$(function() {

  $('.slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '.slider__control--left',
    nextArrow: '.slider__control--right',
    dots: true,
    appendDots: '.slider__dots',
  });

   /* Slide block */
  $('.b__collapse-btn').on('click', function() {

    $('.b__collapse-btn')
      .not($(this))
      .closest('.b--collapsable')
      .addClass('b--collapsed');

    const $target = $(this).closest('.b--collapsable');

    $target.toggleClass('b--collapsed');

    $('html, body').animate({
      scrollTop: $target.offset().top
    }, 400);

  })

});
